<template>
  <div class="popup-successful-add-fact">
    <div class="popup-successful-add-fact__wrapper">
      <Column align="center"
              justify="center">
        <Row>
          <Icon class="popup-successful-add-fact__icon"
                xlink="successfulFact"
                viewport="0 0 105 96"/>
        </Row>
        <p class="popup-successful-add-fact__title">
          Благодарим за добавление</p>
        <p class="popup-successful-add-fact__description">Благодарим за участие. После модерации
          ваша <br>информация появится на портале</p>
        <div>
          <Button v-on:click.native="onMain"
                  class="popup-successful-add-fact__button"
                  color="transparent">на главную
          </Button>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupSuccessfulAddFact',
  methods: {
    onMain() {
      this.$store.commit('CLOSE_POPUP');
      if (this.$route.path !== '/') this.$router.push('/');
    },
  },
};
</script>
